import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('../views/template/login/loginTemplate.vue')
  },
  /* ----- Envio de email para redefinição de senha -------------------------------------------- */
  {
    path: '/resetpasswordsendemail',
    name: 'resetpasswordsendemail',
    component: () => import('../views/template/login/resetPasswordSendEmailTemplate.vue')
  },
  /* ----- Definição da nova senha ------------------------------------------------------------- */
  {
    path: '/newpassword/:token',
    name: 'newpassword',
    component: () => import('../views/template/login/newPasswordTemplate.vue')
  },
  /* ----- Definição da nova senha primeiro acesso --------------------------------------------- */
  {
    path: '/definedpassword/:id',
    name: 'definedpassword',
    component: () => import('../views/template/login/definedPasswordTemplate.vue')
  },
  /* ----- Alteração da senha do usuário do sistema -------------------------------------------- */
  {
    path: '/changepassword',
    name: 'changepassword',
    component: () => import('../views/template/accessControl/perfilTemplate.vue')
  },
  /*----- Dashboard -------------------------------------------------------------------------- */
  {
    path: '/system',
    name: 'system',
    component: () => import('../views/template/dashboard/systemTemplate.vue'),
  },
  {
    path: '/ca',
    name: 'ca',
    component: () => import('../views/template/dashboard/caTemplate.vue'),
  },
  /*----- Business intelligence ---------------------------------------------------------------- */
  {
    path: '/consumption_cost_center',
    name: 'consumption_cost_center',
    component: () => import('../views/template/businessIntelligence/consumptionCostCenterTemplate.vue'),
  },
  {
    path: '/consumption_sector',
    name: 'consumption_sector',
    component: () => import('../views/template/businessIntelligence/consumptionSectorTemplate.vue'),
  },
  {
    path: '/products_evolution',
    name: 'products_evolution',
    component: () => import('../views/template/businessIntelligence/productsEvolutionTemplate.vue'),
  },
  /*----- Alarm -------------------------------------------------------------------------------- */
  {
    path: '/alarm_types',
    name: 'alarm_types',
    component: () => import('../views/template/alarm/alarmTypesTemplate.vue'),
  },
  {
    path: '/alarm_config',
    name: 'alarm_config',
    component: () => import('../views/template/alarm/alarmConfigTemplate.vue'),
  },
  {
    path: '/alarm_alert',
    name: 'alarm_alert',
    component: () => import('../views/template/alarm/alarmAlertTemplate.vue'),
  },
  /*----- Client ------------------------------------------------------------------------------- */
  {
    path: '/company',
    name: 'company',
    component: () => import('../views/template/client/companyTemplate.vue'),
  },
  {
    path: '/companyunit',
    name: 'companyunit',
    component: () => import('../views/template/client/companyUnitTemplate.vue'),
  },
  {
    path: '/zone',
    name: 'zone',
    component: () => import('../views/template/client/zoneTemplate.vue'),
  },
  {
    path: '/sector',
    name: 'sector',
    component: () => import('../views/template/client/sectorTemplate.vue'),
  },
  {
    path: '/feature',
    name: 'feature',
    component: () => import('../views/template/client/featureTemplate.vue'),
  },
  /*----- Produtos ----------------------------------------------------------------------------- */
  {
    path: '/product',
    name: 'product',
    component: () => import('../views/template/products/productTemplate.vue'),
  },
  {
    path: '/category',
    name: 'category',
    component: () => import('../views/template/products/categoryTemplate.vue'),
  },
  {
    path: '/size',
    name: 'size',
    component: () => import('../views/template/products/sizeTemplate.vue'),
  },
  {
    path: '/unitmeasurement',
    name: 'unitmeasurement',
    component: () => import('../views/template/products/unitMeasurementTemplate.vue'),
  },
  /*----- Identificação ------------------------------------------------------------------------ */
  {
    path: '/collaborator',
    name: 'collaborator',
    component: () => import('../views/template/collaborator/collaboratorTemplate.vue'),
  },
  {
    path: '/role',
    name: 'role',
    component: () => import('../views/template/collaborator/roleTemplate.vue'),
  },
  {
    path: '/group',
    name: 'group',
    component: () => import('../views/template/collaborator/groupTemplate.vue'),
  },
  {
    path: '/justification',
    name: 'justification',
    component: () => import('../views/template/collaborator/justificationTemplate.vue'),
  },
  /*----- Relatórios --------------------------------------------------------------------------- */
  {
    path: '/menu_report',
    name: 'menu_report',
    component: () => import('../views/template/report/menuReportTemplate.vue'),
  },
  {
    path: '/delivery_role',
    name: 'delivery_role',
    component: () => import('../views/template/report/deliveryRoleTemplate.vue'),
  },
  {
    path: '/delivery_request',
    name: 'delivery_request',
    component: () => import('../views/template/report/deliveryRequestTemplate.vue'),
  },
  {
    path: '/sheet_epi',
    name: 'sheet_epi',
    component: () => import('../views/template/report/sheetEpiTemplate.vue'),
  },
  /*----- Controle de acesso ------------------------------------------------------------------- */
  {
    path: '/user',
    name: 'user',
    component: () => import('../views/template/accessControl/userTemplate.vue'),
  },
  /*----- Credencial x permissões -------------------------------------------------------------- */
  {
    path: '/credentialPermission',
    name: 'credentialPermission',
    component: () => import('../views/template/accessControl/credentialpermissionTemplate.vue'),
  },
  /*----- Permissões x usuários ---------------------------------------------------------------- */
  {
    path: '/permissionUser',
    name: 'permissionUser',
    component: () => import('../views/template/accessControl/userpermissionTemplate.vue'),
  },
  /*----- Upload empresa ---------------------------------------------------------------------- */
  {
    path: '/upload_company',
    name: 'upload_company',
    component: () => import('../views/template/uploads/companyUploadTemplate.vue'),
  },
  /*----- Upload filial ------------------------------------------------------------------------ */
  {
    path: '/upload_company_unit',
    name: 'upload_company_unit',
    component: () => import('../views/template/uploads/filialUploadTemplate.vue'),
  },
  /*----- Upload produto ----------------------------------------------------------------------- */
  {
    path: '/upload_product',
    name: 'upload_product',
    component: () => import('../views/template/uploads/productUploadTemplate.vue'),
  },
  /*----- Upload colaborador ------------------------------------------------------------------- */
  {
    path: '/upload_collaborator',
    name: 'upload_collaborator',
    component: () => import('../views/template/uploads/collaboratorUploadTemplate.vue'),
  },
   /*----- Upload grupos ----------------------------------------------------------------------- */
   {
    path: '/upload_group',
    name: 'upload_group',
    component: () => import('../views/template/uploads/groupUploadTemplate.vue'),
  },
  /*----- Upload Área -------------------------------------------------------------------------- */
  {
    path: '/upload_zone',
    name: 'upload_zone',
    component: () => import('../views/template/uploads/zoneUploadTemplate.vue'),
  },
  /*----- Upload sector ------------------------------------------------------------------------ */
  {
    path: '/upload_sector',
    name: 'upload_sector',
    component: () => import('../views/template/uploads/sectorUploadTemplate.vue'),
  },
  /*----- Upload usuário ---------------------------------------------------------------------- */
  {
    path: '/upload_user',
    name: 'upload_user',
    component: () => import('../views/template/uploads/userUploadTemplate.vue'),
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
