<template>
  <div class="container-fluid">
    <div v-if="user_logado" class="side-left" id="side-left" :class="classesCSS">
      <Menu/>
    </div>
    <div class="side-right">
      <div v-if="user_logado" class="navbar">
        <Header/>
      </div>
      <div class="content-body">
        <Content/>
      </div>
    </div>
  </div>
</template>

<script>

  import Menu from "@/views/template/common/menuTemplate";
  import Header from "@/views/template/common/headerTemplate";
  import Content from "@/views/template/common/contentTemplate";

  export default {
    data: function (){
      return{
        logado: false,
        mobile: 1
      }
    },
    components:{
      Menu,
      Header,
      Content
    },
    created(){

      const infoUser = JSON.parse(localStorage.getItem("userKey"))

      const Type = typeof infoUser

      if(Type !== null && Type !== undefined){

        this.$store.commit('setUser', infoUser)
      }else{
        this.$store.commit('setUser', null)
      }

      window.addEventListener("resize", this.myEventHandler);
    },
    computed:{
      user_logado(){

        return this.$store.state.user;

      },
      menu_visible(){
        return this.$store.state.isMenuVisible;
      },
      classesCSS: function(){
        return{
          toggle1: !this.menu_visible,
        };
      }
    },
    methods: {
      myEventHandler(e){

        var result_class;

        var sizewidth = e.currentTarget.innerWidth

        if(sizewidth > 768){

            if(this.mobile == 0){

                  result_class = document.getElementById("side-left");

                  if(result_class.classList.contains("toggle1")){

                      document.getElementById('side-left').classList.remove('toggle1');
                  }

                  this.mobile = 1;
            }else{

              result_class = document.getElementById("side-left");

              if(!result_class.classList.contains("toggle1")){
                  this.$store.commit('toggleMenu', true)
              }
            }
        }else{

            if(this.mobile == 1){

              result_class = document.getElementById("side-left");

              if(result_class.classList.contains("toggle1")){

                  document.getElementById('side-left').classList.remove('toggle1');

              }

              this.mobile = 0;

            }else{

              result_class = document.getElementById("side-left");

              if(!result_class.classList.contains("toggle1")){
                  this.$store.commit('toggleMenu', true)
              }
            }
        }

        console.log(this.mobile)

        //console.log(e.currentTarget.innerWidth)
      }
    },
  }
</script>

<style lang="scss">

//novas Sempher

  @media print {

    * {
      color: #000 !important;
    }

    .table > thead > tr > th {
      background-color: rgb(206, 206, 206) !important;
    }

        .side-left, .navbar, .header-form, .print-button {
            display: none !important;
        }
        .card_report {
          box-shadow: none !important;
        }
        .container-fluid {
          background-color: #fff !important;
        }

        body {
          margin: 0;
          padding: 0;
          height:auto !important;
    overflow-y: hidden;
        }

        .content-body, .body_sheet {
          height:auto !important;
          overflow-y: hidden  !important;
        }


    } // fim media print

    .print-button {
      padding: 5px;
      text-align: center;

    }

    .assinatura {
      margin-top: 40px;
    }

    .assinatura p{
      text-align: center !important;
    }
// fim Sempher

    body{
        margin:0px;
        overflow: auto;
    }

  .container-fluid{
    display: flex;
    padding: 0 !important;
    background-color: #f4f6f9;
  }

  .content-body{
    height:100vh;
    overflow-y: auto;
  }

  .side-left{
    min-width: 250px;
    transition: all 0.5s;
  }

  .side-right{
    width:100%;
    overflow: hidden;
  }

  .navbar{
    width:100%;
    padding:0px !important;
  }

  .toggle1{
    margin-left: -250px;
    overflow: hidden;
    transition: all 0.5s;
  }

  @media screen and (max-width: 768px) {
  .side-left{
        width: 250px;
        margin-left: -250px;
        overflow: hidden;
        transition: all 0.5s;
  }

  .toggle1{
        margin-left:0px;
        transition: all 0.5s;
  }
}
</style>
