import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VueApexCharts from "vue3-apexcharts";

import VCalendar from 'v-calendar';
import 'v-calendar/dist/style.css';

import "@/assets/css/global.css"

import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'

import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

import { vMaska } from "maska"

import "bootstrap/dist/css/bootstrap.min.css"

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faRectangleList,faFilter,faBell, faChartLine, faSquarePlus, faSquareMinus, faPlus, faMinus, faMagnifyingGlass, faChartSimple, faAngleRight, faCircle, faUsers, faBox, faUser, faAlignJustify, faRightFromBracket, faUserPen } from '@fortawesome/free-solid-svg-icons'
import { faCuttlefish } from '@fortawesome/free-brands-svg-icons'
import { faRectangleList as farRectangleList } from '@fortawesome/free-regular-svg-icons'


/* add icons to the library */
library.add(farRectangleList, faRectangleList, faFilter, faBell, faChartLine, faSquarePlus, faSquareMinus ,faPlus, faMinus, faMagnifyingGlass,faChartSimple, faAngleRight, faCircle, faUsers, faBox, faUser, faAlignJustify, faRightFromBracket, faUserPen, faCuttlefish)

const options = {
    // You can set your default options here
};

createApp(App).use(store).use(router).use(Toast, options).use(FloatingVue, {
    themes: {
        'info-tooltip': {
        '$extend': 'tooltip',
        '$resetCss': false,
          triggers: ['hover'],
          autoHide: true,
          placement: 'left',
          html: false,
        },
      },
  }).use(VueApexCharts).use(VCalendar, {}).directive("maska", vMaska).component("font-awesome-icon", FontAwesomeIcon).mount('#app')

  
import "bootstrap/dist/js/bootstrap.js"
