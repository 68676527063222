<template>
    <div class="menu">
        <div class="title-menu">
            <div class="title">
                <h5>Controls Admin</h5>
            </div>
        </div>
        <nav class="nav-menu">
            <div class="accordion menu-accordion" id="accordionMenu">
                <div v-if="permissionMenuDashboard" class="accordion-item">
                    <li class="accordion-header" id="headingOne" @click="link_active('main', 'dashboard')">
                        <a data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" :class="{'active_main': active_main == 'dashboard'}">
                            <font-awesome-icon icon="fa-solid fa-chart-simple" />
                            <span>Dashboard</span>
                            <font-awesome-icon icon="fa-solid fa-angle-right" />
                        </a>
                    </li>
                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionMenu">
                        <div class="accordion-body">
                            <ul class="menu-child">
                                <li v-if="permissionSubMenuSystem" @click="link_active('child', 'system')">
                                    <router-link to="/system" :class="{'active': active_link == 'system'}">
                                        <font-awesome-icon icon="fa-solid fa-circle" />
                                        <span>Sistema</span>
                                    </router-link>
                                </li>
                                <li v-if="permissionSubMenuProduct" @click="link_active('child', 'products')">
                                    <router-link to="/ca" :class="{'active': active_link == 'products'}">
                                        <font-awesome-icon icon="fa-solid fa-circle" />
                                        <span>Produtos</span>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div v-if="false" class="accordion-item">
                    <li class="accordion-header" id="headingTwo" @click="link_active('main', 'business')">
                        <a data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo" :class="{'active_main': active_main == 'business'}">
                            <font-awesome-icon icon="fa-solid fa-chart-line" />
                            <span>Business Intelligence</span>
                            <font-awesome-icon icon="fa-solid fa-angle-right" />
                        </a>
                    </li>
                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionMenu">
                        <div class="accordion-body">
                            <ul class="menu-child">
                                <li @click="link_active('child', 'consumption_cost_center')">
                                    <router-link to="/consumption_cost_center" :class="{'active': active_link == 'consumption_cost_center'}">
                                        <font-awesome-icon icon="fa-solid fa-circle" />
                                        <span>Consumo/Centro de Custo</span>
                                    </router-link>
                                </li>
                                <li @click="link_active('child', 'consumption_sector')">
                                    <router-link to="/consumption_sector" :class="{'active': active_link == 'consumption_sector'}">
                                        <font-awesome-icon icon="fa-solid fa-circle" />
                                        <span>Consumo setor</span>
                                    </router-link>
                                </li>
                                <li @click="link_active('child', 'products_evolution')">
                                    <router-link to="/products_evolution" :class="{'active': active_link == 'products_evolution'}">
                                        <font-awesome-icon icon="fa-solid fa-circle" />
                                        <span>Evolução dos Produtos</span>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div v-if="false" class="accordion-item">
                    <li class="accordion-header" id="headingThree" @click="link_active('main', 'alarm')">
                        <a data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree" :class="{'active_main': active_main == 'alarm'}">
                            <font-awesome-icon icon="fa-solid fa-bell" />
                            <span>Alarmes</span>
                            <font-awesome-icon icon="fa-solid fa-angle-right" />
                        </a>
                    </li>
                    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionMenu">
                        <div class="accordion-body">
                            <ul class="menu-child">
                                <li @click="link_active('child', 'alarm_types')">
                                    <router-link to="/alarm_types" :class="{'active': active_link == 'alarm_types'}">
                                        <font-awesome-icon icon="fa-solid fa-circle" />
                                        <span>Tipo de Alarmes</span>
                                    </router-link>
                                </li>
                                <li @click="link_active('child', 'alarm_config')">
                                    <router-link to="/alarm_config" :class="{'active': active_link == 'alarm_config'}">
                                        <font-awesome-icon icon="fa-solid fa-circle" />
                                        <span>Configuração</span>
                                    </router-link>
                                </li>
                                <li @click="link_active('child', 'alarm_alert')">
                                    <router-link to="/alarm_alert" :class="{'active': active_link == 'alarm_alert'}">
                                        <font-awesome-icon icon="fa-solid fa-circle" />
                                        <span>Alertas</span>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div v-if="permissionMenuClient" class="accordion-item">
                    <li class="accordion-header" id="headingFour" @click="link_active('main', 'client')">
                        <a data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour" :class="{'active_main': active_main == 'client'}">
                            <font-awesome-icon icon="fa-solid fa-users" />
                            <span>Clientes</span>
                            <font-awesome-icon icon="fa-solid fa-angle-right" />
                        </a>
                    </li>
                    <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionMenu">
                        <div class="accordion-body">
                            <ul class="menu-child">
                                <li v-if="permissionSubMenuCompany" @click="link_active('child', 'company')">
                                    <router-link to="/company" :class="{'active': active_link == 'company'}">
                                        <font-awesome-icon icon="fa-solid fa-circle" />
                                        <span>Empresas</span>
                                    </router-link>
                                </li>
                                <li v-if="permissionSubMenuCompanyUnit" @click="link_active('child', 'companyunit')">
                                    <router-link to="/companyunit" :class="{'active': active_link == 'companyunit'}">
                                        <font-awesome-icon icon="fa-solid fa-circle" />
                                        <span>Unidades</span>
                                    </router-link>
                                </li>
                                <li v-if="false" @click="link_active('child', 'zone')">
                                    <router-link to="/zone" :class="{'active': active_link == 'zone'}">
                                        <font-awesome-icon icon="fa-solid fa-circle" />
                                        <span>Áreas</span>
                                    </router-link>
                                </li>
                                <li v-if="permissionSubMenuSector" @click="link_active('child', 'sector')">
                                    <router-link to="/sector" :class="{'active': active_link == 'sector'}">
                                        <font-awesome-icon icon="fa-solid fa-circle" />
                                        <span>Áreas</span>
                                    </router-link>
                                </li>
                                <li v-if="permissionSubMenuFeature" @click="link_active('child', 'feature')">
                                    <router-link to="/feature" :class="{'active': active_link == 'feature'}">
                                        <font-awesome-icon icon="fa-solid fa-circle" />
                                        <span>Caracteristicas</span>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div v-if="false" class="accordion-item">
                    <li class="accordion-header" id="headingFive" @click="link_active('main', 'distributed')">
                        <a data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive" :class="{'active_main': active_main == 'distributed'}">
                            <font-awesome-icon icon="fa-solid fa-chart-simple" />
                            <span>Distribuição</span>
                            <font-awesome-icon icon="fa-solid fa-angle-right" />
                        </a>
                    </li>
                    <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionMenu">
                        <div class="accordion-body">
                            <ul class="menu-child">
                                <li @click="link_active('child', 'machine')">
                                    <router-link to="/machine" :class="{'active': active_link == 'machine'}">
                                        <font-awesome-icon icon="fa-solid fa-circle" />
                                        <span>Máquinas</span>
                                    </router-link>
                                </li>
                                <li @click="link_active('child', 'events_log')">
                                    <router-link to="/events_log" :class="{'active': active_link == 'events_log'}">
                                        <font-awesome-icon icon="fa-solid fa-circle" />
                                        <span>Eventos</span>
                                    </router-link>
                                </li>
                                <li @click="link_active('child', 'sinc_machine')">
                                    <router-link to="/sinc_machine" :class="{'active': active_link == 'sinc_machine'}">
                                        <font-awesome-icon icon="fa-solid fa-circle" />
                                        <span>Sincronismo</span>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div v-if="false" class="accordion-item">
                    <li class="accordion-header" id="headingSix" @click="link_active('main', 'storage')">
                        <a data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="true" aria-controls="collapseSix" :class="{'active_main': active_main == 'storage'}">
                            <font-awesome-icon icon="fa-solid fa-chart-simple" />
                            <span>Estoque</span>
                            <font-awesome-icon icon="fa-solid fa-angle-right" />
                        </a>
                    </li>
                    <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionMenu">
                        <div class="accordion-body">
                            <ul class="menu-child">
                                <li @click="link_active('child', 'config')">
                                    <router-link to="/config" :class="{'active': active_link == 'config'}">
                                        <font-awesome-icon icon="fa-solid fa-circle" />
                                        <span>Configuração</span>
                                    </router-link>
                                </li>
                                <li @click="link_active('child', 'input_output')">
                                    <router-link to="/input_output" :class="{'active': active_link == 'input_output'}">
                                        <font-awesome-icon icon="fa-solid fa-circle" />
                                        <span>Entradas x Saídas</span>
                                    </router-link>
                                </li>
                                <li @click="link_active('child', 'release')">
                                    <router-link to="/release" :class="{'active': active_link == 'release'}">
                                        <font-awesome-icon icon="fa-solid fa-circle" />
                                        <span>Lançamento</span>
                                    </router-link>
                                </li>
                                <li @click="link_active('child', 'query_product')">
                                    <router-link to="/query_product" :class="{'active': active_link == 'query_product'}">
                                        <font-awesome-icon icon="fa-solid fa-circle" />
                                        <span>Consulta</span>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div v-if="permissionMenuProduct" class="accordion-item">
                    <li class="accordion-header" id="headingSeven" @click="link_active('main', 'product')">
                        <a data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="true" aria-controls="collapseSeven" :class="{'active_main': active_main == 'product'}">
                            <font-awesome-icon icon="fa-solid fa-users" />
                            <span>Produtos</span>
                            <font-awesome-icon icon="fa-solid fa-angle-right" />
                        </a>
                    </li>
                    <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionMenu">
                        <div class="accordion-body">
                            <ul class="menu-child">
                                <li v-if="permissionSubMenuProducts" @click="link_active('child', 'product')">
                                    <router-link to="/product" :class="{'active': active_link == 'product'}">
                                        <font-awesome-icon icon="fa-solid fa-circle" />
                                        <span>Produtos</span>
                                    </router-link>
                                </li>
                                <li v-if="permissionSubMenuCategory" @click="link_active('child', 'category')">
                                    <router-link to="/category" :class="{'active': active_link == 'category'}">
                                        <font-awesome-icon icon="fa-solid fa-circle" />
                                        <span>Categorias</span>
                                    </router-link>
                                </li>
                                <li v-if="permissionSubMenuSize" @click="link_active('child', 'size')">
                                    <router-link to="/size" :class="{'active': active_link == 'size'}">
                                        <font-awesome-icon icon="fa-solid fa-circle" />
                                        <span>Tamanhos</span>
                                    </router-link>
                                </li>
                                <li v-if="permissionSubMenuUnitMeasurement" @click="link_active('child', 'unitmeasurement')">
                                    <router-link to="/unitmeasurement" :class="{'active': active_link == 'unitmeasurement'}">
                                        <font-awesome-icon icon="fa-solid fa-circle" />
                                        <span>Unidade de Medida</span>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div v-if="false" class="accordion-item">
                    <li class="accordion-header" id="headingEight" @click="link_active('main', 'product')">
                        <a data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="true" aria-controls="collapseEight" :class="{'active_main': active_main == 'product'}">
                            <font-awesome-icon icon="fa-solid fa-users" />
                            <span>Mídias</span>
                            <font-awesome-icon icon="fa-solid fa-angle-right" />
                        </a>
                    </li>
                    <div id="collapseEight" class="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionMenu">
                        <div class="accordion-body">
                            <ul class="menu-child">
                                <li @click="link_active('child', 'moment')">
                                    <router-link to="/moment" :class="{'active': active_link == 'moment'}">
                                        <font-awesome-icon icon="fa-solid fa-circle" />
                                        <span>Momentos</span>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div v-if="permissionMenuIdentification" class="accordion-item">
                    <li class="accordion-header" id="headingNine" @click="link_active('main', 'collaborators')">
                        <a data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="true" aria-controls="collapseNine" :class="{'active_main': active_main == 'collaborators'}">
                            <font-awesome-icon icon="fa-solid fa-users" />
                            <span>Identificação</span>
                            <font-awesome-icon icon="fa-solid fa-angle-right" />
                        </a>
                    </li>
                    <div id="collapseNine" class="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionMenu">
                        <div class="accordion-body">
                            <ul class="menu-child">
                                <li v-if="permissionSubMenuCollaborator" @click="link_active('child', 'collaborators')">
                                    <router-link to="/collaborator" :class="{'active': active_link == 'collaborators'}">
                                        <font-awesome-icon icon="fa-solid fa-circle" />
                                        <span>Colaboradores</span>
                                    </router-link>
                                </li>
                                <li v-if="permissionSubMenuRole" @click="link_active('child', 'role')">
                                    <router-link to="/role" :class="{'active': active_link == 'role'}">
                                        <font-awesome-icon icon="fa-solid fa-circle" />
                                        <span>Funções</span>
                                    </router-link>
                                </li>
                                <li v-if="permissionSubMenuGroup" @click="link_active('child', 'group')">
                                    <router-link to="/group" :class="{'active': active_link == 'group'}">
                                        <font-awesome-icon icon="fa-solid fa-circle" />
                                        <span>Grupos</span>
                                    </router-link>
                                </li>
                                <li v-if="permissionSubMenuJustification" @click="link_active('child', 'justification')">
                                    <router-link to="/justification" :class="{'active': active_link == 'justification'}">
                                        <font-awesome-icon icon="fa-solid fa-circle" />
                                        <span>Justificativas</span>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div v-if="permissionMenuReport" class="accordion-item">
                    <li class="accordion-header" id="headingEleven" @click="link_active('main', 'report')">
                        <a data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="true" aria-controls="collapseEleven" :class="{'active_main': active_main == 'report'}">
                            <font-awesome-icon icon="fa-solid fa-user" />
                            <span>Relatórios</span>
                            <font-awesome-icon icon="fa-solid fa-angle-right" />
                        </a>
                    </li>
                    <div id="collapseEleven" class="accordion-collapse collapse" aria-labelledby="headingEleven" data-bs-parent="#accordionMenu">
                        <div class="accordion-body">
                            <ul class="menu-child">
                                <li v-if="permissionMenuDelivery" @click="link_active('child', 'delivery_epi')">
                                    <router-link to="/delivery_role" class="link" :class="{'active': active_link == 'delivery_epi'}">
                                        <font-awesome-icon icon="fa-solid fa-circle" />
                                        <span>Entregas</span>
                                    </router-link>
                                </li>
                                <li v-if="permissionMenuSheet" @click="link_active('child', 'sheet_epi')">
                                    <router-link to="/sheet_epi" :class="{'active': active_link == 'sheet_epi'}">
                                        <font-awesome-icon icon="fa-solid fa-circle" />
                                        <span>Ficha EPI</span>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div v-if="permissionMenuAccessControl" class="accordion-item">
                    <li class="accordion-header" id="headingtwelve" @click="link_active('main', 'access_control')">
                        <a data-bs-toggle="collapse" data-bs-target="#collapsetwelve" aria-expanded="true" aria-controls="collapsetwelve" :class="{'active_main': active_main == 'access_control'}">
                            <font-awesome-icon icon="fa-solid fa-user" />
                            <span>Controle de acesso</span>
                            <font-awesome-icon icon="fa-solid fa-angle-right" />
                        </a>
                    </li>
                    <div id="collapsetwelve" class="accordion-collapse collapse" aria-labelledby="headingtwelve" data-bs-parent="#accordionMenu">
                        <div class="accordion-body">
                            <ul class="menu-child">
                                <li v-if="permissionUser" @click="link_active('child', 'user')">
                                    <router-link to="/user" class="link" :class="{'active': active_link == 'user'}">
                                        <font-awesome-icon icon="fa-solid fa-circle" />
                                        <span>Usuários</span>
                                    </router-link>
                                </li>
                                <li v-if="permissionListCredential" @click="link_active('child', 'credentialPermission')">
                                    <router-link to="/credentialPermission" :class="{'active': active_link == 'credentialPermission'}">
                                        <font-awesome-icon icon="fa-solid fa-circle" />
                                        <span>Credenciais</span>
                                    </router-link>
                                </li>
                                <li v-if="permissionListPermission" @click="link_active('child', 'permissionUser')">
                                    <router-link to="/permissionUser" :class="{'active': active_link == 'permissionUser'}">
                                        <font-awesome-icon icon="fa-solid fa-circle" />
                                        <span>Permissões de Acesso</span>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div v-if="permissionListUpload" class="accordion-item">
                    <li class="accordion-header" id="headingthirteen" @click="link_active('main', 'uploads')">
                        <a data-bs-toggle="collapse" data-bs-target="#collapsethirteen" aria-expanded="true" aria-controls="collapsethirteen" :class="{'active_main': active_main == 'uploads'}">
                            <font-awesome-icon icon="fa-solid fa-user" />
                            <span>Uploads</span>
                            <font-awesome-icon icon="fa-solid fa-angle-right" />
                        </a>
                    </li>
                    <div id="collapsethirteen" class="accordion-collapse collapse" aria-labelledby="headingthirteen" data-bs-parent="#accordionMenu">
                        <div class="accordion-body">
                            <ul class="menu-child">
                                <li @click="link_active('child', 'upload_company')">
                                    <router-link to="/upload_company" class="link" :class="{'active': active_link == 'upload_company'}">
                                        <font-awesome-icon icon="fa-solid fa-circle" />
                                        <span>Empresas</span>
                                    </router-link>
                                </li>
                                <li @click="link_active('child', 'upload_company_unit')">
                                    <router-link to="/upload_company_unit" :class="{'active': active_link == 'upload_company_unit'}">
                                        <font-awesome-icon icon="fa-solid fa-circle" />
                                        <span>Filiais</span>
                                    </router-link>
                                </li>
                                <li @click="link_active('child', 'upload_zone')">
                                    <router-link to="/upload_zone" :class="{'active': active_link == 'upload_zone'}">
                                        <font-awesome-icon icon="fa-solid fa-circle" />
                                        <span>Áreas</span>
                                    </router-link>
                                </li>
                                <li @click="link_active('child', 'upload_sector')">
                                    <router-link to="/upload_sector" :class="{'active': active_link == 'upload_sector'}">
                                        <font-awesome-icon icon="fa-solid fa-circle" />
                                        <span>Setores</span>
                                    </router-link>
                                </li>
                                <li @click="link_active('child', 'upload_product')">
                                    <router-link to="/upload_product" :class="{'active': active_link == 'upload_product'}">
                                        <font-awesome-icon icon="fa-solid fa-circle" />
                                        <span>Produtos</span>
                                    </router-link>
                                </li>
                                <li @click="link_active('child', 'upload_collaborator')">
                                    <router-link to="/upload_collaborator" :class="{'active': active_link == 'upload_collaborator'}">
                                        <font-awesome-icon icon="fa-solid fa-circle" />
                                        <span>Colaboradores</span>
                                    </router-link>
                                </li>
                                <li @click="link_active('child', 'upload_group')">
                                    <router-link to="/upload_group" :class="{'active': active_link == 'upload_group'}">
                                        <font-awesome-icon icon="fa-solid fa-circle" />
                                        <span>Grupos</span>
                                    </router-link>
                                </li>
                                <li @click="link_active('child', 'upload_user')">
                                    <router-link to="/upload_user" :class="{'active': active_link == 'upload_user'}">
                                        <font-awesome-icon icon="fa-solid fa-circle" />
                                        <span>Usuários</span>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
    export default {
        name: "menuTemplate",
        data: function () {
            return {
                active_main: '',
                active_link: '',
                permissionMenuDashboard: false,
                permissionSubMenuSystem: false,
                permissionSubMenuProduct:false,

                permissionMenuClient: false,
                permissionSubMenuCompany: false,
                permissionSubMenuCompanyUnit: false,
                permissionSubMenuZone: false,
                permissionSubMenuSector: false,
                permissionSubMenuFeature: false,

                permissionMenuProduct           : false,
                permissionSubMenuProducts       : false,
                permissionSubMenuCategory       : false,
                permissionSubMenuSize           : false,
                permissionSubMenuUnitMeasurement: false,

                permissionMenuIdentification  : false,
                permissionSubMenuCollaborator : false,
                permissionSubMenuRole         : false,
                permissionSubMenuGroup        : false,
                permissionSubMenuJustification: false,

                // Menu Relatório
                permissionMenuReport: false,
                permissionMenuDelivery: false,
                permissionMenuSheet   : false,

                // Menu Controle de acesso
                permissionMenuAccessContro: false,
                permissionUser: false,
                permissionListCredential: false,
                permissionListPermission: false,

                // Menu Upload
                permissionListUpload: false
            }
        },
        created(){

           // var sessionUser = JSON.parse( localStorage.getItem('userKey'));

            // Menu Dashboard
            this.permissionMenuDashboard  = true //sessionUser.permissions.indexOf('100000')  !== -1 ? true : false
            this.permissionSubMenuSystem  = true //sessionUser.permissions.indexOf('99000')  !== -1 ? true : false
            this.permissionSubMenuProduct = true //sessionUser.permissions.indexOf('98000')  !== -1 ? true : false

            // Menu Client
            this.permissionMenuClient         = true //sessionUser.permissions.indexOf('97000')  !== -1 ? true : false
            this.permissionSubMenuCompany     = true //sessionUser.permissions.indexOf('96000')  !== -1 ? true : false
            this.permissionSubMenuCompanyUnit = true //sessionUser.permissions.indexOf('91000')  !== -1 ? true : false
            this.permissionSubMenuZone        = true //sessionUser.permissions.indexOf('86000')  !== -1 ? true : false
            this.permissionSubMenuSector      = true //sessionUser.permissions.indexOf('81000')  !== -1 ? true : false
            this.permissionSubMenuFeature     = true //sessionUser.permissions.indexOf('76000')  !== -1 ? true : false

            // Menu Product
            this.permissionMenuProduct        = true //sessionUser.permissions.indexOf('74000')  !== -1 ? true : false
            this.permissionSubMenuProducts    = true //sessionUser.permissions.indexOf('73000')  !== -1 ? true : false
            this.permissionSubMenuCategory    = true //sessionUser.permissions.indexOf('68000')  !== -1 ? true : false
            this.permissionSubMenuSize        = true //sessionUser.permissions.indexOf('63000')  !== -1 ? true : false
            this.permissionSubMenuUnitMeasurement = true //sessionUser.permissions.indexOf('58000')  !== -1 ? true : false

            // Menu Identificação
            this.permissionMenuIdentification  = true //sessionUser.permissions.indexOf('53000')  !== -1 ? true : false
            this.permissionSubMenuCollaborator = true //sessionUser.permissions.indexOf('52000')  !== -1 ? true : false
            this.permissionSubMenuRole         = true //sessionUser.permissions.indexOf('47000')  !== -1 ? true : false
            this.permissionSubMenuGroup        = true //sessionUser.permissions.indexOf('42000')  !== -1 ? true : false
            this.permissionSubMenuJustification= true //sessionUser.permissions.indexOf('37000')  !== -1 ? true : false

            // Menu Relatório
            this.permissionMenuReport         = true //sessionUser.permissions.indexOf('32000')  !== -1 ? true : false
            this.permissionMenuDelivery       = true //sessionUser.permissions.indexOf('32000')  !== -1 ? true : false
            this.permissionMenuSheet          = true //sessionUser.permissions.indexOf('32000')  !== -1 ? true : false

            // Menu Controle de acesso
            this.permissionMenuAccessControl  = true //sessionUser.permissions.indexOf('29000')  !== -1 ? true : false
            this.permissionUser               = true //sessionUser.permissions.indexOf('28000')   !== -1 ? true : false
            this.permissionListCredential  = true //sessionUser.permissions.indexOf('23000')   !== -1 ? true : false
            this.permissionListPermission  = true //sessionUser.permissions.indexOf('21000')   !== -1 ? true : false

            // Menu Upload
            this.permissionListUpload      = true //sessionUser.permissions.indexOf('19000')   !== -1 ? true : false
            
            this.link_active('main', 'dashboard')
        },
        computed: {
            menu_visible() {
                return this.$store.state.isMenuVisible;
            }
        },
        methods:{
            link_active(menu, rota) {

                if (menu == 'main') {

                    this.active_main = rota
                }
                if (menu == 'child') {
                    this.active_link = rota
                }
            }
        }
    }
</script>

<style scoped>
/* ----- Título do menu ------------------------------------------------------------------------ */
.menu {
    height: 100vh;
    background-image: linear-gradient(45deg, #65379b, #886aea);
    overflow-y: auto;
}

.menu .title-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    background-color: transparent;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.menu .title-menu h5 {
    margin: 0;
    font-size: 16px;
    color: #ffffff;
    font-family: Arial;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 0.5px;
}

/* ----- Menu Principal ------------------------------------------------------------------------ */
.menu .nav-menu {
    padding: 0px;
    background-color: transparent;
}

.menu .nav-menu .menu-accordion {
    background-color: transparent;
}

.menu .nav-menu .menu-accordion .accordion-item {
    border: none !important;
    background-color: transparent !important;
}

.menu .nav-menu .accordion-header {
    list-style-type: none;
    padding: 0;
    text-align: left;
}

.menu .nav-menu .accordion-header a {
    color: rgba(255, 255, 255, 0.65);
}

.menu .nav-menu .accordion-header a {
    padding: 11px 5px 11px 15px;
    line-height: 23px;
    font-size: 15px;
    display: block;
    font-family: Arial;
    font-weight: 400;
    text-decoration: none;
    border-left: 3px solid transparent;
    cursor: pointer;
}

.nav-menu .accordion-header a svg {
    margin-right: 10px;
    font-size: 18px;
}

.nav-menu .accordion-header a .fa-angle-right {
    margin-top: 5px;
    font-size: 15px;
    float: right;
    margin-right: 20px;
}

.nav-menu .accordion-header:hover>a {
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.15);
    border-left-color: #ffffff;
}

.nav-menu .accordion-header a.active_main {
    color: #fff !important;
    background-color: rgba(255, 255, 255, 0.15);
    border-left-color: #ffffff;
}

/* ----- Menu filho ---------------------------------------------------------------------------- */
.nav-menu .accordion-body {
    margin: 0;
    padding: 0;
}

.nav-menu .accordion-body .menu-child {
    margin: 0;
    padding: 0px 0px 0px 15px;
    list-style-type: none;
    text-align: left;
}

.nav-menu .accordion-body li {
    margin: 0;
    padding: 6px 5px 6px 15px;
}

.nav-menu .accordion-body a {
    line-height: 20px;
    text-decoration: none;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.65);
    font-family: Arial;
}

.nav-menu .accordion-body .fa-circle {
    position:relative;
    top:0.02rem;
    font-size: 10px;
}

.nav-menu .accordion-body li span {
    position: relative;
    top: 2px;
    left: 10px;
}

.nav-menu .accordion-body li:hover>a {
    color: #ffffff;
    background-color: transparent;
    border-left-color: transparent;
}

.nav-menu .accordion-body .active {
    color: #fff !important;
}
</style>